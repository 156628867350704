<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/Admin/index' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>交易查询</el-breadcrumb-item>
                    <el-breadcrumb-item>历史记录</el-breadcrumb-item>
                    <el-breadcrumb-item>列表</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="admin_main_block top">
            <el-row class="total-header">
                <el-col :span="span4" class="span4">
                    <el-row>
                        <el-col :span="span12">交易总笔数:</el-col>
                        <el-col :span="span10"><span>{{total.count}}</span></el-col>
                    </el-row>
                </el-col>
                <el-col :span="span4" class="span4">
                    <el-row>
                        <el-col :span="span12">入账总金额:</el-col>
                        <el-col :span="span10"><span>{{total.addAmount}}</span></el-col>
                    </el-row>
                </el-col>
                <el-col :span="span4" class="span4">
                    <el-row>
                        <el-col :span="span12">出账总金额:</el-col>
                        <el-col :span="span10"><span>{{total.subAmount}}</span></el-col>
                    </el-row>
                </el-col>
            </el-row>
        </div>
        <div class="admin_main_block admin_m15">
            <div class="header-form">
                <div class="admin_main_block_left">
                    <el-form>
                        <el-form-item label="交易起止日期">
                            <el-date-picker
                                    v-model="date"
                                    @change="changeDate" @input="immediUpdate"
                                    type="daterange"
                                    range-separator="~"
                                    value-format="yyyy-MM-dd"
                                    format="yyyy-MM-dd"
                                    start-placeholder="开始日期"
                                    end-placeholder="截止日期"
                                    :clearable="true"
                                    unlink-panels
                            >
                                <i class="el-icon-date"></i>
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="账户编号">
                            <el-input v-model="where.accountNo" placeholder="请输入账户编号" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="金额发生方向">
                            <el-select v-model="where.accountForward" placeholder="请选择账户类型">
                                <el-option label="全部" value=""></el-option>
                                <el-option label="进账" value="ADD"></el-option>
                                <el-option label="出账" value="SUB"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" plain icon="el-icon-search" @click="listData()">条件筛选</el-button>
                        </el-form-item>
                    </el-form>
                </div>

                <div class="admin_main_block_right">
                    <el-row :gutter="20">
                        <el-col :span="12">

                        </el-col>
                        <el-col :span="12">
                            <el-button
                                    type="primary"
                                    @click="exportData"
                                    disabled
                                    icon="el-icon-tickets">导出数据</el-button>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div class="admin_table_main">
                <el-table
                        :data="list"
                        v-loading="loading"
                        element-loading-text="拼命加载中"
                        element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(0, 0, 0, 0.1)"
                >
                    <el-table-column prop="id" label="ID"></el-table-column>
                    <el-table-column prop="accountForward" label="账户类型"></el-table-column>
                    <el-table-column prop="amount" label="金额"></el-table-column>
                    <el-table-column prop="bizTypeText" label="业务类型"></el-table-column>
                    <el-table-column prop="flowId" label="业务流水"></el-table-column>
                    <el-table-column prop="createTime" label="创建时间"></el-table-column>
                </el-table>
                <div class="admin_table_main_pagination">
                    <el-pagination
                            @current-change="currentChange"
                            @size-change="handleSizeChange"
                            background
                            :total="totalData"
                            :page-size="pageSize"
                            :current-page="currentPage"
                            :page-sizes="[10, 20, 50, 100]"
                            layout="sizes, prev, pager, next, total, jumper"
                    ></el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        list as api_list,
        history as api_history,
    } from "@/api/modules/account/history/index";
    export default {
        components: {
        },
        props: {
            state: Number,
        },
        data() {
            return {
                span: 6,
                span4: 5,
                span12: 12,
                span10: 10,
                list: [],
                date: "",
                row: {},
                loading: false,
                dialogDetailVisible: false,
                dialogEditVisible: false,
                totalData: 0, // 总条数
                pageSize: 20,
                currentPage: 0,
                selectId: "",
                where: {
                    pageNum:0,//分页，页数
                    pageSize:20,//分页，每页大小
                    accountId:'',// 账户编号
                    accountForward:'',// 金额发生方向【ADD-进账  SUB-出账】
                    startDt:'',    // 开始日期，格式【yyyy-MM-dd】
                    endDt:''    // 结束日期，格式【yyyy-MM-dd】
                },
                total:{
                    count: 0,        // 账户交易总数
                    addAmount: 0,  // 进账总金额
                    subAmount: 0,   // 出账总金额
                },
            };
        },
        methods: {
            async listData() {
                this.loading = true;
                const {code, data} = await api_list(this.where);
                if(code === 1){
                    this.totalData = data.total;
                    this.list = data.records;
                    this.loading = false;
                    this.getTotal();
                }
            },
            handleSelectionChange(e) {
                let ids = [];
                e.forEach((v) => {
                    ids.push(v.id);
                });
                this.selectId = ids.join(",");
            },
            currentChange(e) {
                this.where.pageNum = this.currentPage = e;
                this.listData();
            },
            handleSizeChange(e) {
                this.where.pageSize = this.pageSize = e;
                this.listData();
            },
            changeDate(obj) {
                if (obj) {
                    this.where.startDt = obj[0];
                    this.where.endDt = obj[1];
                }
            },
            immediUpdate(e) {
                this.$nextTick(() => {
                    console.log(e)
                    if(e==null){
                        this.where.startDt = '';
                        this.where.endDt = '';
                    }
                });
            },
            exportData() {
                // console.log("exportData");
            },
            async getTotal(){
                const {code, data} = await api_history(this.where);
                if(code === 1){
                    this.total = data;
                }
            },
        },
        created() {
            this.where.accountId = this.$route.query.accountId;
            this.listData();
            this.getTotal();
        },
    };
</script>
<style lang="scss" scoped>
    .total-header {
        margin-bottom: 0px !important;
        margin-top: 15px;

        .el-col {
            margin-right: 15px;

            :last-child {
                margin-right: 0px;
            }
        }
    }

    .el-row {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .el-col {
        border-radius: 4px;
    }

    .header-form {
        height: 40px;
        padding: 10px 0 10px 0;
    }

    .span4 {
        background: rgb(217, 236, 255);
        font-size: 16px;
        padding: 30px 0;
        text-align: center;
        vertical-align: center;

        span {
            font-size: 18px;
        }
    }
    .endDate {
        color: #c0c4cc;
    }

    .el-image {
        height: 100px;
        width: 100px;
    }

    .el-select {
        width: 120px;
    }
    .SYSTEM,.PROFIT_PAY{
        color: #67C23A;
    }
    .SHOP,.PROFIT_PAY{
        color: #E6A23C;
    }
    .SERVICE_PROVIDER,.PROFIT_BEAN{
        color: #F56C6C;
    }
    .PERSON,.CON_VAL{
        color: #909399;
    }
    .OPERATION_CENTER,.AD_PAY{
        color: #409EFF;
    }
    .OPERATION_CENTER,.AD_PAY{
        color: #a84a26;
    }
</style>
<style>
    .el-drawer__header span:focus {
        outline: 0 !important;
    }
</style>
