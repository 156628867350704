/**
 *@Author:navies
 *@Date:2021-09-30
 *@Project:后台管理系统
 */
// eslint-disable-next-line no-unused-vars
import {get, post} from '@/plugins/http.js'

export const baseUrl = process.env.VUE_APP_API_URL;

/**
 * 列表
 * @param data
 */
export const list = (data) => {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
        post(baseUrl + "system/accountHistory/getList", data).then((res) => {
            resolve(res);
        });
    });

};
/**
 * 统计
 * @param data
 */
export const history = (data) => {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
        post(baseUrl + "system/accountHistory/getAccountHistoryStatistics", data).then((res) => {
            resolve(res);
        });
    });

};
